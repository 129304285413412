import { cloneDeep, findIndex, get, isNil, uniqBy } from "lodash";
import { CurveType } from "../constans/feedingTypes";
import { SettingTypes } from "../constans/settingTypes";
// import store from "../store/store";

export const initialValue = {
    feedingCurves: [],
    feedingSchedules: [],
    forage: [],
    forageHistory: [],
    layout: undefined,
    settings: [],
    plan: undefined,
    general: undefined,
    commands: undefined,
    notifications: undefined,
    showAppConf: false,
    layouts: [],
    layoutsSelect: [],
    economy: undefined,
    raspberrys: [],
    farmMap: new Map(),
    endpointArn: null,
    fetching: false,
    updating: false,
    adding: false,
    climateCurves: [],
    climateCurvesSK3: [],
    deviceSetting: null,
    scheduleWorktypes: [],
    systemSchedules: [],
    file: null,
    ocrTemplates: [],
    protocol: {
        data: null,
        loading: false
    },
    economySettings: null,
    customTasks: [],
    herdSettings: null,
    routines: [],
    technologyGroupTasks: null,
    cardTemplates: [],
    permissionTemplates: []
};

export default function settingsReducer(state = initialValue, action) {
    switch (action.type) {
        case "CHANGE_SHOW_AREA_CONFIG":
            return {
                ...state,
                showAreaConfig: action.payload,
                showDevicesConfig: false,
                showNotifications: false,
                showLicense: false,
                showSMS: false,
                showEmail: false,
                showAppConf: false
            };
        case "CHANGE_SHOW_DEVICES_CONFIG":
            return {
                ...state,
                showAreaConfig: false,
                showDevicesConfig: action.payload,
                showNotifications: false,
                showLicense: false,
                showSMS: false,
                showEmail: false,
                showAppConf: false
            };
        case "CHANGE_SHOW_NOTIFICATIONS":
            return {
                ...state,
                showAreaConfig: false,
                showDevicesConfig: false,
                showNotifications: action.payload,
                showLicense: false,
                showSMS: false,
                showEmail: false,
                showAppConf: false
            };
        case "CHANGE_SHOW_SMS":
            return {
                ...state,
                showSMS: action.payload,
                showEmail: false,
                showAppConf: false
            };
        case "CHANGE_SHOW_EMAIL":
            return {
                ...state,
                showEmail: action.payload,
                showSMS: false,
                showAppConf: false
            };
        case "CHANGE_SHOW_APP_CONF":
            return {
                ...state,
                showAppConf: action.payload,
                showEmail: false,
                showSMS: false
            };
        case "CHANGE_SHOW_LICENSE":
            return {
                ...state,
                showAreaConfig: false,
                showDevicesConfig: false,
                showNotifications: false,
                showLicense: action.payload,
                showSMS: false,
                showEmail: false,
                showAppConf: false
            };
        case "CHANGE_SHOW_PAYMENT_METHODS":
            return {
                ...state,
                showPaymentMethods: action.payload,
                showSubscriptions: false
            };
        case "CHANGE_SHOW_SUBSCRIPTIONS":
            return {
                ...state,
                showPaymentMethods: false,
                showSubscriptions: action.payload
            };
        case "CLOSE_ALL_TABS":
            return {
                ...state,
                showAreaConfig: false,
                showDevicesConfig: false,
                showNotifications: false,
                showLicense: false,
                showSMS: false,
                showEmail: false,
                showAppConf: false,
                showPaymentMethods: false,
                showSubscriptions: false
            };
        case "CHANGE_FARM":
        case "USER_LOGOUT_FULFILLED": {
            return initialValue;
        }
        case "GET_PERMISSIONS_FULFILLED":
        case "GET_PERMISSIONS": {
            let tmp = cloneDeep(action.payload);
            let permissionTemplates = tmp.filter(set => set.SetType === SettingTypes.PERMISSION_TEMPLATES);
            return {
                ...state,
                permissionTemplates
            }
        }
        
        case "LIST_ALL_SETTINGS": {
            let tmp = cloneDeep(action.payload);
            //sortowanko zeby uniqBy bral najnowsze rzeczy jak sie powtarzaja na dynamo
            tmp.sort((o1, o2) => +o2.DtaModTime - +o1.DtaModTime);
            // console.log(tmp, "sorted");
            let curves = uniqBy(tmp.filter(set => set.SetType === "C" && get(set, "SetData.Index") !== undefined && Object.values(CurveType).includes(set.SetData.Type)), o => get(o, "SetData.Index"));
            let schedules = uniqBy(tmp.filter(set => set.SetType === "S" && get(set, "SetData.Index") !== undefined).sort((o1, o2) => o2.DtaModTime - o1.DtaModTime), o => get(o, "SetData.Index"));
            let plan = tmp.filter(set => set.SetType === "P")[0];
            let forage = tmp.filter(set => set.SetType === "F" && !set.DtaDelTime).sort((o1, o2) => o2.DtaModTime - o1.DtaModTime);
            let forageHistory = tmp.filter(set => set.SetType === "F" && set.DtaDelTime);
            let general = (() => {
                return tmp.filter(set => set.SetType === "X" && set.SetID === `${set.ClientID}_${set.FarmID}`)[0];
            })();
            let layouts = tmp.filter(set => set.SetType === "L");
            let farm = tmp.filter(set => set.SetType === "FM" && set.SetData && set.SetData.Levels);
            let tmpFarmMap = new Map();
            farm.map(farmMap => {
                if (farmMap.SetID.startsWith("farm_map_")) {
                    tmpFarmMap.set(farmMap.SetID.replace("farm_map_", ""), farmMap);
                }
            });
            let layout = layouts[0];
            let economy = tmp.filter(set => set.SetType === "E")[0];
            let frames = tmp.filter(set => set.SetType === "FRM");
            let commands = tmp.filter(set => set.SetType === "CMD");
            let climateCurvesSK3 = uniqBy(tmp.filter(set => set.SetType === "CC3" && !isNil(set.SetData.Index)), o => get(o, "SetData.Index"));
            if (layout) {
                Object.keys(layout.SetData.Layout).map(key => {
                    return layout.SetData.Layout[key] = layout.SetData.Layout[key].map(item => {
                        return {
                            ...item, y: item.y === -1 ? Infinity : item.y
                        };
                    });
                });
            }
            let raspberrys = tmp.filter(set => set.SetType === "T");
            let endpointArn = tmp.filter(set => set.SetType === "ARN");
            let notification = tmp.filter(set => set.SetType === SettingTypes.NOTIFICATIONS && set.LocalUserID === action.meta.user.LocalUserID)[0];
            let climateCurves = tmp.filter(set => set.SetType === SettingTypes.CLIMATE_CURVE);
            let deviceSetting = tmp.find(set => set.SetType === SettingTypes.DEVICE_SETTINGS);
            let scheduleWorktypes = tmp.filter(set => set.SetType === SettingTypes.SYSTEM_SCHEDULE_WORKTYPE);
            let systemSchedules = tmp.filter(set => set.SetType === SettingTypes.SYSTEM_SCHEDULE);
            let file = tmp.find(set => set.SetType === SettingTypes.FILE);
            let ocrTemplates = tmp.filter(set => set.SetType === SettingTypes.OCR_TEMPLATE);
            let economySettings = tmp.find(set => set.SetType === SettingTypes.ECONOMY_SETTINGS);
            let herdSettings = tmp.find(set => set.SetType === SettingTypes.HERD_SETTINGS);
            let customTasks = tmp.filter(set => set.SetType === SettingTypes.CUSTOM_TASKS);
            let routines = tmp.filter(set => set.SetType === SettingTypes.ROUTINES);
            let technologyGroupTasks = tmp.find(set => set.SetType === SettingTypes.TECHNOLOGY_GROUP_TASKS);
            let cardTemplates = tmp.filter(set => set.SetType === SettingTypes.CARD_TEMPLATES && !set.DtaDelTime);
            let permissionTemplates = tmp.filter(set => set.SetType === SettingTypes.PERMISSION_TEMPLATES);
            // console.log("LAYOUT", layout);
            return {
                ...state,
                settings: tmp,
                feedingCurves: curves,
                feedingSchedules: schedules,
                plan: plan,
                forage: forage,
                forageHistory: forageHistory,
                general: general,
                fetching: false,
                layouts: layouts,
                frames: frames,
                commands: commands,
                farmMap: tmpFarmMap,
                layoutsSelect: layouts.map(layout => {
                    return {
                        label: layout.SetData.Name,
                        value: layout
                    };
                }),
                economy: economy,
                raspberrys: raspberrys,
                endpointArn: endpointArn,
                notifications: notification,
                climateCurves: climateCurves,
                deviceSetting,
                scheduleWorktypes,
                systemSchedules,
                climateCurvesSK3,
                file,
                ocrTemplates,
                economySettings,
                herdSettings,
                customTasks,
                routines,
                technologyGroupTasks,
                cardTemplates,
                permissionTemplates
            };
        }
        
        case "CREATE_SETTING_PENDING": {
            return {
                ...state,
                adding: true
            };
        }
        case "CREATE_SETTING_REJECTED":
        case "CREATE_SETTING_FULFILLED": {
            return {
                ...state,
                adding: false
            };
        }
        case "UPDATE_SETTING_PENDING": {
            return {
                ...state,
                updating: true
            };
        }
        case "UPDATE_SETTING_REJECTED":
        case "UPDATE_SETTING_FULFILLED": {
            return {
                ...state,
                updating: false
            };
        }
        case "GET_SETTINGS_PENDING": {
            return {
                ...state,
                fetching: true
            };
        }
        case "GET_SETTINGS_REJECTED":
        case "GET_SETTINGS_FULFILLED": {
            return {
                ...state,
                fetching: false
            };
        }
        case "SET_LAYOUT": {
            let layout = action.payload;
            if (layout) {
                Object.keys(layout.SetData.Layout).map(key => {
                    return layout.SetData.Layout[key] = layout.SetData.Layout[key].map(item => {
                        return {
                            ...item, y: item.y === -1 ? Infinity : item.y
                        };
                    });
                });
            }
            return {
                ...state, layout: layout
            };
        }
        case "GET_COMMISSIONING_PROTOCOL_PENDING":
            return { ...state, protocol: { ...state.protocol, loading: true } };
        case "GET_COMMISSIONING_PROTOCOL_FULFILLED":
            return { ...state, protocol: { ...state.protocol, loading: false, data: action.payload } };
        case "GET_COMMISSIONING_PROTOCOL_REJECTED":
            return { ...state, protocol: { ...state.protocol, loading: false } };
        case "ADD_NEW_CURVE_OFFLINE": {
            const currentCurves = state.feedingCurves.slice();
            const currentSettings = state.settings.slice();
            currentCurves.push(action.payload);
            currentSettings.push(action.payload);
            return { ...state, feedingCurves: currentCurves, settings: currentSettings };
        }
        case "UPDATE_CURVE_OFFLINE": {
            const currentCurves = state.feedingCurves.slice();
            let curveIndex = findIndex(currentCurves, o => o.SetID === action.payload.SetID);
            currentCurves.splice(curveIndex, 1);
            currentCurves.push(action.payload);
            const currentSettings = state.settings.slice();
            let settingsIndex = findIndex(currentSettings, o => o.SetID === action.payload.SetID);
            currentSettings.splice(settingsIndex, 1);
            currentSettings.push(action.payload);
            return { ...state, feedingCurves: currentCurves, settings: currentSettings };
        }
        case "ADD_NEW_SCHEDULE_OFFLINE": {
            const currentSchedules = state.feedingSchedules.slice();
            const currentSettings = state.settings.slice();
            currentSchedules.push(action.payload);
            currentSettings.push(action.payload);
            return { ...state, feedingSchedules: currentSchedules, settings: currentSettings };
        }
        case "UPDATE_SCHEDULE_OFFLINE": {
            const currentSchedules = state.feedingSchedules.slice();
            let curveIndex = findIndex(currentSchedules, o => o.SetID === action.payload.SetID);
            currentSchedules.splice(curveIndex, 1);
            currentSchedules.push(action.payload);
            const currentSettings = state.settings.slice();
            let settingsIndex = findIndex(currentSettings, o => o.SetID === action.payload.SetID);
            currentSettings.splice(settingsIndex, 1);
            currentSettings.push(action.payload);
            return { ...state, feedingSchedules: currentSchedules, settings: currentSettings };
        }
        case "ADD_NEW_CLIMATE_CURVE_OFFLINE": {
            const currentCurves = state.climateCurvesSK3.slice();
            const currentSettings = state.settings.slice();
            currentCurves.push(action.payload);
            currentSettings.push(action.payload);
            return { ...state, climateCurvesSK3: currentCurves, settings: currentSettings };
        }
        case "UPDATE_CLIMATE_CURVE_OFFLINE": {
            const currentCurves = state.climateCurvesSK3.slice();
            let curveIndex = findIndex(currentCurves, o => o.SetID === action.payload.SetID);
            currentCurves.splice(curveIndex, 1);
            currentCurves.push(action.payload);
            const currentSettings = state.settings.slice();
            let settingsIndex = findIndex(currentSettings, o => o.SetID === action.payload.SetID);
            currentSettings.splice(settingsIndex, 1);
            currentSettings.push(action.payload);
            return { ...state, climateCurvesSK3: currentCurves, settings: currentSettings };
        }
        case "SHOW_EDIT_PERMISSION_MODAL":
            return {...state, showEditPermissionModal: true, currentPermission: action.payload};
        case "HIDE_EDIT_PERMISSION_MODAL":
            return {...state, showEditPermissionModal: false, currentPermission: null};
        default:
            return state;
    }
}
