import React from "react";
import PropTypes from "prop-types";
import Button from "../button/Button";
import {isObject} from "lodash";
import {withTranslation} from "react-i18next";
import {isUsingFakeData} from "../../../utils/DemoUtils";

class MenuItem extends React.Component {

    render() {
        const {children, id, disableInDemo = false, t, noAccess} = this.props;
        const extraProps = {};
        if (isUsingFakeData() && disableInDemo) {
            extraProps.disabled = true;
        }
        return (
            <li id={id}>
                {!!extraProps.disabled && (
                    <div className={"disable-text"}>
                        {t("notAvailableInDemo")}
                    </div>
                )}
                {!!isObject(children) && children}
                {!isObject(children) && (
                    <Button
                        stopPropagation={false}
                        onClick={this.props.onClick}
                        icon={<i className={"fa-fw fas fa-blank"} />}
                        {...this.props}
                        {...extraProps}
                        type={"button"}
                        noAccess={noAccess}
                        buttonColor={"menu-color"}>
                        {children}
                    </Button>
                )}
            </li>
        );
    }

}


MenuItem.propTypes = {
    onClick: PropTypes.func,
    icon: PropTypes.node,
    id: PropTypes.string,
    disableInDemo: PropTypes.bool,
    noAccess: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired
};

export default withTranslation()(MenuItem);
