import {getUserApiData} from "../../utils/UserUtils";
import {invokeApig} from "../../libs/awsLib";
import Paths from "../paths";

export async function getPermissionsList({ClientID} = {}) {
    const {_ClientID} = getUserApiData({ClientID});
    let res = await invokeApig({
        ...Paths.getPermissionsList({clientID: _ClientID}),
    });
    return res;
}