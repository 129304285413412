import AnimalTypes from "@wesstron/utils/Api/constants/animalTypes";
import {get} from "lodash";
import moment from "moment";
import {createSelector} from "reselect";
import EstimatedWeight from "../beans/estimated-weight/EstimatedWeight";
import {applyOffset} from "../components/animal-documents/ideal-charts/charts/utils";
import {utcMomentToLocal} from "../utils/DateTimeUtils";
import {
    getFirstCycleIndex as getFirstCycleIndexUtil,
    getOtherWeights,
    getTargetForageLevel,
    getTargetForageLevelOffset,
    getTargetWaterLevel,
    getTargetWaterLevelOffset,
    getTimeFromInseminationToPartuition,
} from "../utils/SettingsUtils";

export const getFileSettings = (state) => state.settings.file;

export const getForages = (state) => state.settings.forage;

export const getHistoricalForages = (state) => state.settings.forageHistory;

export const getOCRTemplates = (state) => state.settings.ocrTemplates;

export const getFirstIndexOfCycle = (state) =>
    get(state, "settings.general.SetData.Settings.Cycle.FirstCycleIndex", 0);

export const getShowGiltsOnParturition = (state) =>
    get(
        state,
        "settings.general.SetData.Settings.Cycle.ShowGiltsOnParturition",
        true
    );

const getOCRName = (state, {name}) => name;

const _getCardTemplates = (state) => state.settings.cardTemplates;

export const getCardTemplates = createSelector(
    _getCardTemplates,
    (templates) => {
        return templates.map((item) => ({
            data: item.SetData,
            id: item.SetID,
        }));
    }
);

export const getOCRTemplate = createSelector(
    getOCRTemplates,
    getOCRName,
    (templates, name) => {
        return templates.find((item) => item.SetID === name);
    }
);

export const getCommissioningProtocolLoading = (state) =>
    state.settings.protocol.loading;

export const getCommissioningProtocol = (state) => state.settings.protocol.data;

export const getFeedingSchedules = (state) => state.settings.feedingSchedules;

export const getFeedingCurves = (state) => state.settings.feedingCurves;

export const getClimateCurvesSK3 = (state) => state.settings.climateCurvesSK3;

export const getCustomTasks = (state) => state.settings.customTasks;

export const getSettingsAdding = (state) => state.settings.adding;

export const getSettingsUpdating = (state) => state.settings.updating;

export const getGeneralSettings = (state) => state.settings.general;

export const getListOfTemplates = state =>  state.settings?.permissionTemplates;

export const getPermissionTemplates = createSelector(getListOfTemplates, templates => {
    const obj = {}
    for (let template of templates){
        if(!template.DtaDelTime)
            obj[template.SetID] = template.SetData
    }
    return obj
})
const emptyObj = {};

export const getValidationSettings = (state) =>
    get(state, "settings.general.SetData.Settings.Validations", emptyObj);

export const getPlan = (state) => state.settings.plan;

export const getTechnologyGroupTasks = (state) =>
    state.settings.technologyGroupTasks;

export const getFeedingLevels = (state) => {
    const defaultValue = {
        FeedingMinimumLevel: 0.1,
        FeedingMaximumLevel: 0.75,
    };
    try {
        return (
            state.settings.general.SetData.Settings.FeedingLevels ||
            defaultValue
        );
    } catch (e) {
        return defaultValue;
    }
};

export const getTimeForFirstMilk = (state) => {
    const defaultValue = 1000 * 60 * 60 * 24;
    try {
        return (
            state.settings.general.SetData.Settings.FirstMilk.Time ||
            defaultValue
        );
    } catch (e) {
        return defaultValue;
    }
};

export const getTimeFromInseminationToPregnancy = (state) => {
    let defaultValue = 28;
    try {
        return (
            state.settings.general.SetData.Settings.Cycle
                .TimeFromInseminationToPregnancy || defaultValue
        );
    } catch (e) {
        return defaultValue;
    }
};

export const getPigletTreatmentSettings = createSelector(
    getGeneralSettings,
    (settings) => ({
        CastrationDays: settings?.SetData?.Settings?.Cycle?.CastrationDays || [
            5, 8,
        ],
        TailsDays: settings?.SetData?.Settings?.Cycle?.TailsDays || [5, 8],
        TeethDays: settings?.SetData?.Settings?.Cycle?.TeethDays || [5, 8],
        WeightingDays: settings?.SetData?.Settings?.Cycle?.WeightingDays || [
            5, 8,
        ],
    })
);

export const getOtherWeightsSelector = createSelector(
    getGeneralSettings,
    getOtherWeights
);

export const getTargetWater = createSelector(
    getGeneralSettings,
    (generalSettings) => {
        return {
            Offset: getTargetWaterLevelOffset(generalSettings),
            WeeklyTarget: getTargetWaterLevel(generalSettings),
        };
    }
);

export const getTargetForage = createSelector(
    getGeneralSettings,
    (generalSettings) => {
        return {
            Offset: getTargetForageLevelOffset(generalSettings),
            WeeklyTarget: getTargetForageLevel(generalSettings),
        };
    }
);

export const getAllForages = createSelector(
    [getForages, getHistoricalForages],
    (forages, historicalForages) => [...historicalForages, ...forages]
);

const getGroupInfo = (_, groupInfo) => groupInfo;

export const getExpectedWaterUsageForGroup = createSelector(
    getTargetWater,
    getGroupInfo,
    (targetWater, groupInfo) => {
        const estimate = new EstimatedWeight(0, []);
        estimate.setWeightTable(targetWater.WeeklyTarget);
        estimate.setBirthDate(groupInfo.birthTime);
        const diff = Math.abs(
            moment.utc(groupInfo.start).diff(moment.utc(groupInfo.end), "day")
        );
        let sum = 0;
        for (let i = 0; i <= diff; i++) {
            const dayUTC = moment.utc(groupInfo.start).add(i, "day");
            const dayLOCAL = utcMomentToLocal(dayUTC);
            sum += estimate.getWeightByDate(+dayLOCAL);
        }
        const offset = [
            applyOffset(sum, -targetWater.Offset),
            applyOffset(sum, targetWater.Offset),
        ];
        return {min: offset[0], max: offset[1]};
    }
);

export const getExpectedForageUsageForGroup = createSelector(
    getTargetForage,
    getGroupInfo,
    (targetForage, groupInfo) => {
        const estimate = new EstimatedWeight(0, []);
        estimate.setWeightTable(targetForage.WeeklyTarget);
        estimate.setBirthDate(groupInfo.birthTime);
        const diff = Math.abs(
            moment.utc(groupInfo.start).diff(moment.utc(groupInfo.end), "day")
        );
        let sum = 0;
        for (let i = 0; i <= diff; i++) {
            const dayUTC = moment.utc(groupInfo.start).add(i, "day");
            const dayLOCAL = utcMomentToLocal(dayUTC);
            sum += estimate.getWeightByDate(+dayLOCAL);
        }
        const offset = [
            applyOffset(sum, -targetForage.Offset),
            applyOffset(sum, targetForage.Offset),
        ];
        return {min: offset[0], max: offset[1]};
    }
);

const defaultChartOptions = {
    StrokeWidth: "NORMAL",
    ReferenceLineFontSize: "NORMAL",
    GroupReferenceLineType: "WEEK",
    ReferenceLineTextPosition: "AUTO",
    ScatterShape: "circle",
};

export const makeGetChartSettings = () =>
    createSelector(getGeneralSettings, (generalSettings) => {
        if (!generalSettings?.SetData?.Settings?.Charts)
            return defaultChartOptions;
        return {
            ...defaultChartOptions,
            ...generalSettings.SetData.Settings.Charts,
        };
    });

export const makeGetChartStrokeWidth = () =>
    createSelector(getGeneralSettings, (generalSettings) => {
        if (!generalSettings?.SetData?.Settings?.Charts?.StrokeWidth) return 4;
        if (generalSettings.SetData.Settings.Charts.StrokeWidth === "THIN")
            return 2;
        if (generalSettings.SetData.Settings.Charts.StrokeWidth === "THICK")
            return 6;
        return 4;
    });

export const makeGetChartReferenceLineFontSize = () =>
    createSelector(getGeneralSettings, (generalSettings) => {
        if (!generalSettings?.SetData?.Settings?.Charts?.ReferenceLineFontSize)
            return 1;
        if (
            generalSettings.SetData.Settings.Charts.ReferenceLineFontSize ===
            "SMALL"
        )
            return 0.8;
        if (
            generalSettings.SetData.Settings.Charts.ReferenceLineFontSize ===
            "BIG"
        )
            return 1.2;
        return 1;
    });

export const makeGetGroupReferenceLineType = () =>
    createSelector(getGeneralSettings, (generalSettings) => {
        if (!generalSettings?.SetData?.Settings?.Charts?.GroupReferenceLineType)
            return defaultChartOptions.GroupReferenceLineType;
        return generalSettings.SetData.Settings.Charts.GroupReferenceLineType;
    });

export const makeGetReferenceLineTextPosition = () =>
    createSelector(getGeneralSettings, (generalSettings) => {
        if (
            !generalSettings?.SetData?.Settings?.Charts
                ?.ReferenceLineTextPosition
        )
            return defaultChartOptions.ReferenceLineTextPosition;
        return generalSettings.SetData.Settings.Charts
            .ReferenceLineTextPosition;
    });

export const makeGetScatterShape = () =>
    createSelector(getGeneralSettings, (generalSettings) => {
        if (!generalSettings?.SetData?.Settings?.Charts?.ScatterShape)
            return defaultChartOptions.ScatterShape;
        return generalSettings.SetData.Settings.Charts.ScatterShape;
    });

export const getFatteningDaysForPiglet = createSelector(
    getGeneralSettings,
    (generalSettings) =>
        generalSettings?.SetData?.Settings?.Cycle
            ?.FatteningDaysForPiglet?.[1] || 70
);

export const getFatteningDaysForPorker = createSelector(
    getGeneralSettings,
    (generalSettings) =>
        generalSettings?.SetData?.Settings?.Cycle
            ?.FatteningDaysForPorker?.[1] || 90
);

export const getResultsTarget = (state) =>
    state.settings.general?.SetData?.Settings?.Results || null;

const emptyArray = [];
export const getGaugeList = (state) =>
    state.settings.general?.SetData?.Settings?.ResultsInfo || emptyArray;

export const getSowCardTemplates = createSelector(
    _getCardTemplates,
    (templates) => {
        return templates.filter(
            (item) => item.SetData.AnimalType === AnimalTypes.SOW
        );
    }
);

export const getTimeFromInseminationToParturition = createSelector(
    getGeneralSettings,
    (generalSettings) => {
        return getTimeFromInseminationToPartuition(
            generalSettings?.SetData?.Settings?.Cycle
        );
    }
);

export const getFirstCycleIndex = createSelector(
    getGeneralSettings,
    (generalSettings) => {
        return getFirstCycleIndexUtil(
            generalSettings?.SetData?.Settings?.Cycle
        );
    }
);

export const getParametersConstData = createSelector(
    getTimeFromInseminationToParturition,
    getFirstCycleIndex,
    getTimeFromInseminationToPregnancy,
    (
        timeFromInseminationToParturition,
        firstCycleIndex,
        timeFromInseminationToPregnancy
    ) => {
        return {
            timeFromInseminationToParturition,
            firstCycleIndex,
            timeFromInseminationToPregnancy,
        };
    }
);


export const getPermissionModal = state => state.settings.showEditPermissionModal;

export const getCurrentPermission = state => state.settings.currentPermission;

export const getListOfPermissions = state => state.user.user.localPermissions

function flattenObject(obj, result = {}) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        if (typeof value === "boolean" && value === false) {
            if (result[key] === undefined) {
                result[key] = value;
            }
        } else if (typeof value === "object" && value !== null) {
            flattenObject(value, result);
        }
      }
    }
    return result;
  }

export const getLocalPermission = createSelector(getListOfTemplates, getListOfPermissions, (templates, listOfPermissions) => {
    let flattenData = {};
    for (let item of listOfPermissions || []) {
        const template = templates.find(({SetID}) => SetID === item);
        if (template) {
            if (!template.DtaDelTime) {
                const data = template.SetData.Data;
                flattenObject(data, flattenData);
            }
        } else {
            console.log("localPermission template not found", item);
            flattenData = "loading";
            break;
        }
    };
      console.log(flattenData,listOfPermissions, templates,'localPermission')
    return flattenData

})