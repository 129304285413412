import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import parametersDB from "../database/parametersDB";
import animalsDB from "../database/animalsDB";
import eventsDB from "../database/eventsDB";
import {makeGetBuildingsMap} from "../selectors/buildingsSelector";
import {useSelector} from "react-redux";
import {isMobile} from "../utils/MobileUtils";
import {getUtilResults} from "../utils/results/GeneralResultsUtils";
import useLocalStorage from "./useLocalStorage";
import groupsDB from "../database/groupsDB";
import {getFarmID} from "../selectors/farmSelector";

export function useCalculateAnimalParams(AnmID) {
    const [fullParametersCalc] = useLocalStorage(
        "fullParametersCalc",
        false,
        false
    );

    const [loading, setLoading] = useState(
        isMobile() && !fullParametersCalc ? true : false
    );

    const getBuildingsMapParams = useRef({showDeleted: true});
    const getBuildingsMap = useMemo(makeGetBuildingsMap, []);

    const buildingsMap = useSelector((state) =>
        getBuildingsMap(state, getBuildingsMapParams.current)
    );

    const onMessage = useCallback(() => {
        console.error("onMessage");
        setLoading(false);
    }, []);

    const farm = useSelector(getFarmID);

    useEffect(() => {
        if (!AnmID || !isMobile() || fullParametersCalc) return;

        setLoading(true);
        parametersDB.sendMessageToWorker(
            "CALCULATE_ANIMAL",
            {
                animal: animalsDB.getAnimalById(AnmID, {
                    joinEvents: false,
                    findDeleted: true,
                }),
                events: eventsDB.getAllEvents4Animal(AnmID),
                utilResults: getUtilResults(),
                buildingsMap: Array.from(buildingsMap),
                groups: groupsDB.getAllGroups(farm, {showDeleted: true}),
            },
            onMessage
        );
    }, [AnmID, onMessage, fullParametersCalc]); // eslint-disable-line react-hooks/exhaustive-deps

    return loading;
}
